import React from "react"

import $ from "jquery"
const ModalForm = (props) => {
	if (props.status === "add" || props.status === "edit") {
		setTimeout(() => {
			$("#modal-form").modal({
				backdrop: "static",
				keyboard: true,
				show: true,
			})
		}, 100)
	} else {
		setTimeout(() => {
			$("#modal-form").modal("hide")
		}, 100)
	}
	return (
		<div>
			<div className="modal fade" id="modal-form">
				<div className={`modal-dialog ${props.mobil ? "modal-xl" : ""}`}>
					<div className="modal-content rounded-0">
						<div className="modal-header bg-light text-muted p-1">
							<div className="container p-0">
								<div className="row">
									<div className="col-9 col-sm-9">
										<button type="button" className="btn rounded-0 m-0 btn-light text-muted">
											<strong>{props.title} &nbsp; Form</strong>
										</button>
									</div>
									<div className="col-3 col-sm-3 text-right">
										<button className="btn btn-sm rounded-0 m-0 border-0 btn-secondary" onClick={() => props.set_state_one("status", "")}>
											<i className="fa fa-times"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-body slot-container">{props.children}</div>
						<div className="modal-footer p-1">
							<input type="hidden" />
							<button type="button" onClick={() => props.post_()} className="btn btn-sm rounded-0 btn-secondary">
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalForm
