import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { gapi } from 'gapi-script';
import { GoogleLogout } from 'react-google-login';
import lib from "../libs";
import ekr from '../assets/img/bg-masthead.jpg';

const NavBars = (props) => {
    const datas = useSelector((state) => state.crud)
    const dispatch = useDispatch();

    const onLogout = (res) => {
        console.log("Log out successfull !")
        console.log(res)
        dispatch({ type: 'SET_SSO_GOOGLE', payload: [] })
    }
    return (
        <div className="mb-2">

            <nav className="navbar navbar-expand-sm navbar-light rounded-0 p-2 bg-white shadow-sm border mt-1">
                <div className="d-flex w-100 rounded-0 p-0">
                    <div className="p-0 flex-grow-1 bd-highlight">
                        <a className="navbar-brand text-danger" href="/">
                            <img src={ekr} width={50} />Elektrindo Kreasi Raya
                        </a>
                    </div>
                    {datas.ssoEmail.profileObj ? (
                        <>
                            <div className="media">
                                <img src={datas.ssoEmail.profileObj.imageUrl} className="align-self-start navbar-brand mr-2" width="30px" alt="..." />
                                <div className="media-body">

                                    <div className="btn-group btn-group-sm">
                                        <button className="btn text-primary border-0 btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                            {datas.ssoEmail.profileObj.email}
                                        </button>
                                        <div className="dropdown-menu rounded-0 p-2 text-center">
                                            <div className="d-flex flex-column bd-highlight">
                                                <div className="p-0 bd-highlight">
                                                    <img src={datas.ssoEmail.profileObj.imageUrl} width="120px" height="120" className="img-thumbnail mb-1 border-0" alt="my sso" />
                                                </div>
                                                <div className="p-1 text-center mb-1"><small className="text-muted"><b>{datas.ssoEmail.profileObj.email}</b></small></div>
                                                <div className="p-1 text-center"><small className="text-dark">{`${datas.ssoEmail.profileObj.name}`}</small></div>
                                                <div className="p-1 text-center border-top">
                                                    <div id="signOutButton">
                                                        <GoogleLogout
                                                            clientId={lib.publics.ssoIdClient}
                                                            buttonText="Logout Google"
                                                            onLogoutSuccess={onLogout}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* <div className="p-0">
                                <button className="btn btn-sm nav-link text-primary border-right" onClick={props.openRegister}><b>Register</b></button>
                            </div> */}
                            <div className="p-0">
                                <button className="btn btn-sm nav-link text-primary" onClick={props.openLogin}><b>Login</b></button>
                            </div>
                        </>
                    )}
                </div>
            </nav>

        </div>

    );
};
export default NavBars;
