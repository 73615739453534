import React from "react";
import ImagesGallery from "../Images/ImagesGallery";
import { useNavigate } from "react-router-dom";

import lib from "../../libs";
const OtherProduct = (props) => {
    const navigate = useNavigate();
    //const datas = useSelector((state) => state.crud)
    const reloadingTol = () => {
        //window.location.reload();
    }
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <h3>Products</h3>
                </div>
            </div>
            <div className="row">
                {props.datagrid.slice(0, 6).map((row, no) => (
                    <div className="col-md-4" key={row.id}>
                        <div className="card p-0 bg-white rounded-0 border shadow-none">
                            <ImagesGallery id={row.id} checkThumbnail={row.uploads} />
                            <div className="card-body p-0">
                                <div className="d-flex flex-column p-2 bd-highlight">
                                    <div className="p-0 mb-0">{`${row.Tahun}, ${row.Type}, ${row.Km} Km, ${row.PlatNo}`}</div>
                                    <div className="p-1 text-center text-danger"><b>{`${lib.formatter.formatCurrencyIDR(row.Harga)}`}</b></div>
                                    <div className="p-1">
                                        <div className="d-flex p-0">
                                            <div className="p-0 flex-grow-1 bd-highlight">
                                                <button className="btn rounded-pill w-75 btn-sm btn-outline-primary" onClick={() => [navigate(`/${row.id}`), reloadingTol()]}>Detail</button>
                                            </div>
                                            <div className="p-0"><i className="fa fa-eye text-muted"></i></div>
                                            <div className="p-0 text-muted ml-1"><small>167</small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="row justify-content-center">
                <div className="col-7 col-md-3 text-center">
                    <a className="btn btn-success btn-block border rounded-0" href="/">
                        All Products
                    </a>
                </div>
            </div>

        </div>
    );
};
export default OtherProduct;
