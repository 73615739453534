import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { create, update, index, remove } from "../actions/crud"
import { useNavigate } from "react-router-dom"

import ModalConfirm from "../components/Modals/ModalConfirm"
import ModalSuccess from "../components/Modals/ModalSuccess"
import PaginationList from "../components/Paginations/PaginationList"
import TabTop from "../components/TabBarComponents/TabTop"
import TabTitle from "../components/TabBarComponents/TabTitle"
import FormInput from "../components/Forms/FormInput"
import lib from "../libs"
import ModalForm from "../components/Modals/ModalForm"
import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData"
import ImagesInside from "../components/Images/ImagesInside"

const Mobil = () => {
	const initialState = {
		title: "Mobil",
		api: "mobil",
		modal: false,
		status: "",
		success: "",
		reload: true,
		keySearch: ["Type", "Tahun"],
		form: {
			id: "",
			merk: "",
			Type: "",
			Tahun: "",
			PlatNo: "",
			Warna: "",
			Km: "",
			Harga: "",
			Transmisi: "",
			BahanBakar: "",
			NoRangka: "",
			Jenis: "",
			KapasitasMesin: "",
			NoMesin: "",
			STNK: "",
			BPKB: "",
			FotokopiKTP: "",
			FormA: "",
			MasaSTNK: "",
			Faktur: "",
			KwitansiBlanka: "",
			Keur: "",
			SPH: "",
		},
	}

	const scrollableDivStyle = {
		maxHeight: "450px", // Set a maximum height for the div
		overflowY: "auto", // Enable vertical scrolling if content overflows// Optional border for visual clarity
	}
	//const URL_IMAGE = 'http://localhost:8181/uploads/';
	const inputFile = {
		display: "none",
	}

	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [obj, setObj] = useState(initialState)
	const datas = useSelector((state) => state.crud)
	const dispatch = useDispatch()

	const handleInputChange = (key, event) => {
		console.log(event)
		if (key !== "") {
			delete datas.errors[key]
		}
		setObj((prevState) => ({
			...prevState,
			form: {
				...prevState.form,
				[key]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
		}))
	}

	const set_state_one = (key, val) => {
		setObj((prevData) => ({
			...prevData,
			[key]: val,
		}))
	}

	const set_state_two = (key, val) => {
		setObj((prevData) => ({
			...prevData,
			form: {
				...prevData.form,
				[key]: val,
			},
		}))
	}

	const remove_state_two = (outerKey, innerKeyToRemove) => {
		setObj((prevData) => {
			const newData = { ...prevData }
			if (newData[outerKey]) {
				delete newData[outerKey][innerKeyToRemove]
			}
			return newData
		})
	}

	const reset_ = () => {
		for (const key in initialState.form) {
			delete datas.errors[key]
			setObj((prevState) => ({
				...prevState,
				form: {
					...prevState.form,
					[key]: "",
				},
			}))
		}
	}

	useEffect(() => {
		dispatch(index(obj.api))
			.then((data) => {
				if (data.success) {
					setLoading(true)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}, [])

	const edit_ = (id) => {
		reset_()
		datas.data.parents.forEach((row) => {
			if (row.id === id) {
				for (const key in initialState.form) {
					if (key === "merk") {
						set_state_two(key, row[key]._id)
					} else {
						set_state_two(key, row[key])
					}
				}
			}
		})
		set_state_one("modal", true)
		set_state_one("status", "edit")
	}

	//removeNestedKey
	const add_ = () => {
		reset_()
		remove_state_two("form", "id")
		set_state_one("status", "add")
		set_state_one("modal", true)
	}
	const remove_ = (row) => {
		reset_()
		datas.data.parents.forEach((r) => {
			if (r.id === row.id) {
				for (const key in initialState.form) {
					set_state_two(key, row[key])
				}
			}
		})
		set_state_one("status", "remove")
	}

	const successfull = (msg) => {
		set_state_one("status", "")
		set_state_one("success", msg)
	}

	const post_ = () => {
		if (obj.status === "add") {
			dispatch(create(obj))
				.then((data) => {
					if (data.success) {
						successfull("saved")
						const objs = {
							data: data.data,
							parentTabel: "merk",
							childCount: "childCount",
							keyId: data.data.merk._id,
						}
						dispatch({ type: "INCREMENT_PARENT_COUNT", payload: objs })
					}
				})
				.catch((e) => {
					console.log(e)
				})
		} else if (obj.status === "edit") {
			dispatch(update(obj))
				.then((data) => {
					if (data.success) {
						successfull("Updated")
					}
				})
				.catch((e) => {
					console.log(e)
				})
		} else if (obj.status === "remove") {
			dispatch(remove(obj))
				.then((data) => {
					if (data.success) {
						successfull("Removed")
						const objs = {
							data: data.data,
							parentTabel: "merk",
							childCount: "childCount",
							keyId: data.data.merk._id,
						}
						dispatch({ type: "DECREMENT_PARENT_COUNT", payload: objs })
					}
				})
				.catch((e) => {
					console.log(e)
				})
		}
	}

	return (
		<div>
			<TabTitle title={obj.title} />
			<div className="tab-content border border-top-0 p-2 bg-white">
				<div className="tab-pane fade show active">
					{!loading ? (
						<SpinnerFetchingData />
					) : (
						<>
							<div className="container-fluid border-0 p-2">
								<div className="row justify-content-between">
									<div className="col-12 col-sm-3 mt-2">
										<div className="table-responsive table-responsive-sm p-0 mb-3" style={scrollableDivStyle}>
											<table className={`${lib.className.table.parent}`}>
												<thead>
													<tr>
														<th className={`${lib.className.table.th} text-center`} colSpan="2">
															Merk Mobil
														</th>
													</tr>
												</thead>
												<tbody>
													{datas.data.childs.merk.map((row, no) => (
														<tr key={no}>
															<td className="p-0 w-100 border-top-0 border-left-0 border-right-0 text-primary border-bottom btn btn-sm rounded-0 text-left" onClick={() => dispatch({ type: "FILTER_SINGLE_PARENT", payload: row._id })}>
																<div className="d-flex">
																	<div className="p-0 flex-grow-1">{row.name}</div>
																	<div className="p-0 border-0">{row.childCount > 0 ? <small className="bg-success pl-1 text-light pr-1">{row.childCount}</small> : <small className="bg-danger pl-1 pr-1 text-light">{row.childCount}</small>}</div>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
									<div className="col-12 col-sm-9">
										<TabTop parent_add={add_} keySearch={obj.keySearch} />
										{datas.data.parents.slice(datas.start, datas.end).map((row, no) => (
											<div className="media mb-1 border-bottom p-1" key={row.id}>
												<ImagesInside id={row.id} checkThumbnail={row.uploads} />

												<div className="media-body">
													<b className="mt-0 text-muted">{row.merk.name} </b> <span className="text-info">{row.Type}</span>
													<br />
													<span className="text-muted">Tahun : </span> <span>{row.Tahun} </span>,&nbsp;
													<span className="text-muted">Plat No : </span> <span>{row.PlatNo} </span>,&nbsp;
													<span className="text-muted">Warna : </span> <span>{row.Warna} </span>,&nbsp;
													<span className="text-muted">Km : </span> <span>{row.Km} </span>
													<br />
													<div className="d-flex">
														<div>
															<span className="text-muted">Harga : </span>
															<span className="text-danger">&nbsp;{lib.formatter.formatCurrencyIDR(row.Harga)}</span>
														</div>
														<div className="ml-auto">
															<div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
																<button className="btn btn-sm btn-light border text-info rounded-0 p-0 pl-1 pr-1" onClick={() => edit_(row.id)}>
																	<i className={`${lib.className.icon.edit}`}></i>
																</button>
																<button className="btn btn-sm btn-light mr-1 ml-1 border text-danger rounded-0 p-0 pl-1 pr-1" onClick={() => remove_(row)}>
																	<i className={`${lib.className.icon.remove}`}></i>
																</button>

																<button className="btn btn-sm btn-light text-success border rounded-0 p-0 pl-1 pr-1" onClick={() => navigate(`/gallery/${row.id}`)}>
																	<i className={`${lib.className.icon.image}`}></i>
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
							<PaginationList />
							<ModalForm title={obj.title} post_={post_} status={obj.status} set_state_one={set_state_one} mobil>
								<div className="row">
									<div className="col">
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Merk" keyName="merk" keyValue={obj.form.merk} typeForm="selectapi" col="9" arr={datas.data.childs.merk} fields="name" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Transimisi" keyName="Transmisi" keyValue={obj.form.Transmisi} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Bahan Bakar" keyName="BahanBakar" keyValue={obj.form.BahanBakar} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Nomor Rangka" keyName="NoRangka" keyValue={obj.form.NoRangka} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Jenis" keyName="Jenis" keyValue={obj.form.Jenis} typeForm="text" col="9" />
									</div>
									<div className="col">
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Kapasitas Mesin (cc)" keyName="KapasitasMesin" keyValue={obj.form.KapasitasMesin} typeForm="number" col="3" setmaxLength={6} />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Km" keyName="Km" keyValue={obj.form.Km} typeForm="number" col="3" setmaxLength={8} />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Nomor Mesin" keyName="NoMesin" keyValue={obj.form.NoMesin} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Type" keyName="Type" keyValue={obj.form.Type} typeForm="text" col="9" />
									</div>
								</div>
								<hr />
								<div className="row">
									<div className="col">
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Plat No" keyName="PlatNo" keyValue={obj.form.PlatNo} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="STNK" keyName="STNK" keyValue={obj.form.STNK} typeForm="boolean" col="1" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="BPKB" keyName="BPKB" keyValue={obj.form.BPKB} typeForm="number" col="3" setmaxLength={6} />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Fotokopi KTP" keyName="FotokopiKTP" keyValue={obj.form.FotokopiKTP} typeForm="boolean" col="1" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Form A" keyName="FormA" keyValue={obj.form.FormA} typeForm="boolean" col="1" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="KEUR" keyName="Keur" keyValue={obj.form.Keur} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Warna" keyName="Warna" keyValue={obj.form.Warna} typeForm="selectstatic" col="9" lists={lib.jsonStatic.colors} />
									</div>
									<div className="col">
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Masa STNK" keyName="MasaSTNK" keyValue={obj.form.MasaSTNK} typeForm="date" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Faktur" keyName="Faktur" keyValue={obj.form.Faktur} typeForm="boolean" col="1" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Kwitansi Blanka" keyName="KwitansiBlanka" keyValue={obj.form.KwitansiBlanka} typeForm="boolean" col="1" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="SPH" keyName="SPH" keyValue={obj.form.SPH} typeForm="text" col="9" />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Tahun" keyName="Tahun" keyValue={obj.form.Tahun} typeForm="selectstatic" col="3" lists={lib.jsonStatic.years} />
										<FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Harga" keyName="Harga" keyValue={obj.form.Harga} typeForm="number" col="5" setmaxLength={12} />
									</div>
								</div>
							</ModalForm>

							<ModalSuccess success={obj.success} set_state_one={set_state_one} />

							<ModalConfirm childPost={post_} status={obj.status} teks={obj.form.name} />
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default Mobil
