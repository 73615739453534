import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { create, update, index, remove } from "../actions/crud";

import ModalConfirm from "../components/Modals/ModalConfirm";
import ModalSuccess from "../components/Modals/ModalSuccess";
import PaginationList from "../components/Paginations/PaginationList";
import TabTop from "../components/TabBarComponents/TabTop";
import TabTitle from '../components/TabBarComponents/TabTitle';
import FormInput from '../components/Forms/FormInput'
import lib from '../libs';
import ModalForm from "../components/Modals/ModalForm";
import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData";


const Merk = () => {
  const initialState = {
    title: 'Merk',
    api: 'merk',
    modal: false,
    status: '',
    success: '',
    reload: true,
    keySearch: ['name','id'],
    form: {
      id: '',
      name: ''
    }
  };

  const scrollableDivStyle = {
    maxHeight: '450px', // Set a maximum height for the div
    overflowY: 'auto', // Enable vertical scrolling if content overflows// Optional border for visual clarity
  };




  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState(initialState);
  const datas = useSelector((state) => state.crud)
  const dispatch = useDispatch();

  const handleInputChange = (key, event) => {
    if (key !== '') {
      delete datas.errors[key];
    }
    setObj((prevState) => ({
      ...prevState, form: {
        ...prevState.form, [key]: event.target.value
      },
    }))
  };

  const set_state_one = (key, val) => {
    setObj(prevData => ({
      ...prevData,
      [key]: val,
    }));
  };

  const set_state_two = (key, val) => {
    setObj(prevData => ({
      ...prevData,
      form: {
        ...prevData.form,
        [key]: val,
      },
    }));
  };


  const remove_state_two = (outerKey, innerKeyToRemove) => {
    setObj(prevData => {
      const newData = { ...prevData };
      if (newData[outerKey]) {
        delete newData[outerKey][innerKeyToRemove];
      }
      return newData;
    });
  };

  const reset_ = () => {
    for (const key in initialState.form) {
      delete datas.errors[key];
      setObj((prevState) => ({
        ...prevState, form: {
          ...prevState.form, [key]: ''
        },
      }))
    }
  }



  useEffect(() => {
    dispatch(index(obj.api))
      .then(data => {
        if (data.success) {
          setLoading(true)
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);


  const edit_ = (id) => {
    reset_()
    datas.data.parents.forEach(row => {
      if (row.id === id) {
        for (const key in initialState.form) {
            set_state_two(key, row[key])      
        }
      }
    })
    set_state_one('modal', true)
    set_state_one('status', 'edit')
  }

  //removeNestedKey
  const add_ = () => {
    //datas.itemPerPageActive = 10;
    reset_()
    remove_state_two('form', 'id')
    set_state_one('status', 'add')
    set_state_one('modal', true)

  }
  const remove_ = (row) => {
    reset_()
    datas.data.parents.forEach(r => {
      if (r.id === row.id) {
        for (const key in initialState.form) {
          set_state_two(key, row[key])
        }
      }
    })
    set_state_one('status', 'remove')
  }

  const successfull = (msg) => {
    set_state_one('status', '')
    set_state_one('success', msg)
  }

  const post_ = () => {

    if (obj.status === 'add') {
      dispatch(create(obj))
        .then(data => {
          if (data.success) {
            successfull("saved")
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else if (obj.status === 'edit') {
      dispatch(update(obj))
        .then(data => {
          if (data.success) {
            successfull("Updated")
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else if (obj.status === 'remove') {
      dispatch(remove(obj))
        .then(data => {
          if (data.success) {
            successfull("Removed")
          }
        })
        .catch(e => {
          console.log(e);
        });
    }

  };


  return (
    <div>
      <TabTitle title={obj.title} />
      <div className="tab-content border border-top-0 p-2 bg-white">
        <div className="tab-pane fade show active">
          {datas.data.parents == undefined && datas.data.parents == null ? (
            <SpinnerFetchingData />
          ) : (
            <>
              <div className="container-fluid border-0 p-2">
                <div className="row justify-content-between">
                  <div className="col-12 col-sm-12">
                    <TabTop parent_add={add_} keySearch={obj.keySearch} />
                    <div className="table-responsive p-0 mb-2">
                      <table className={`${lib.className.table.style}`}>
                        <thead>
                          <tr>
                            <th width="6%" className={`bg-white border-0 ${lib.className.table.th}`}></th>
                            <th width="6%" className={`${lib.className.table.th} text-center`}>No.</th>
                            <th className={`${lib.className.table.th}`}>Name</th>                  
                          </tr>
                        </thead>
                        <tbody>

                          {datas.data.parents.slice(datas.start, datas.end).map((row, no) => (
                            <tr className="text-muted" key={no}>
                              <td className="text-center p-0 border-left-0">
                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                  <button className="btn btn-sm btn-light mr-1 text-info rounded-0 p-0"
                                    onClick={() => edit_(row.id)}>
                                    <i className={`${lib.className.icon.edit}`}></i>
                                  </button>
                                  <button className="btn btn-sm btn-light text-danger rounded-0 p-0"
                                    onClick={() => remove_(row)}>
                                    <i className={`${lib.className.icon.remove}`}></i>
                                  </button>
                                </div>
                              </td>
                              <td className={`${lib.className.table.td} text-center`}>{no + (datas.start + 1)} .</td>
                              <td className={`${lib.className.table.td}`}>&nbsp;{row.name} </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <PaginationList
              />
              <ModalForm
                title={obj.title}
                post_={post_}
                status={obj.status}
                set_state_one={set_state_one}
              >
                <FormInput handleInputChange={handleInputChange} eror={datas.errors} labelName="Nama" keyName="name" keyValue={obj.form.name} typeForm="text" col='9' />
              </ModalForm>

              <ModalSuccess
                success={obj.success}
                set_state_one={set_state_one}
              />

              <ModalConfirm
                childPost={post_}
                status={obj.status}
                teks={obj.form.name}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Merk;

