import { legacy_createStore as createStore } from "redux"

import { CREATE_DATA, UPDATE_DATA, INDEX_DATA, SET_PAGE, REMOVE_DATA, SEARCH_DATA, SET_ITEM_PER_PAGE, ERROR_INPUT, FILTER_SINGLE_PARENT, INCREMENT_PARENT_COUNT, DECREMENT_PARENT_COUNT, FILTERING, RELOADING, SET_SSO_GOOGLE, UPLOAD_FAKTUR } from "../actions/types"

//import lib from '../libs';
import lib from "../libs"

const initialState = {
	data: [],
	alldata: [],
	ssoEmail: [],
	childs: [],
	filter: "no",
	datafilter: [],
	errors: [],
	itemPagesList: [5, 10, 15, 20],
	itemPerPageActive: 5,
	pageActive: 0,
	start: 0,
	end: 5,
	faktur: "",
}

var json = []
const crud = (state = initialState, action) => {
	const { type, payload, errors } = action
	switch (type) {
		case FILTERING:
			//console.log(action.payload.keyword)
			json = lib.jsons.filtering(action.payload.keyword, state.alldata.parents)

			console.log(state)
			console.log(json)

			return {
				...state,
				data: {
					...state.data,
					parents: json,
				},
				faktur: "Success",
			}

		case RELOADING:
			return {
				...state,
				data: { ...state.data, parents: state.alldata.parents },
			}
		case UPLOAD_FAKTUR:
			return {
				...state,
				data: { ...state.data, parents: state.alldata.parents },
			}

		case FILTER_SINGLE_PARENT:
			console.log(payload)

			return {
				...state,
				data: {
					...state.data,
					parents: state.alldata.parents.filter((row) => row.merk._id === payload),
				},
				pageActive: 0,
			}

		case DECREMENT_PARENT_COUNT:
			return {
				...state,
				data: {
					...state.data,
					childs: {
						...state.data.childs,
						[payload.parentTabel]: state.data.childs[payload.parentTabel].map((child) => {
							if (child._id === payload.keyId) {
								return {
									...child,
									[payload.childCount]: child[payload.childCount] - 1,
								}
							}
							return child
						}),
					},
				},
			}

		case INCREMENT_PARENT_COUNT:
			return {
				...state,
				data: {
					...state.data,
					childs: {
						...state.data.childs,
						[payload.parentTabel]: state.data.childs[payload.parentTabel].map((child) => {
							if (child._id === payload.keyId) {
								return {
									...child,
									[payload.childCount]: child[payload.childCount] + 1,
								}
							}
							return child
						}),
					},
				},
			}

		case SET_SSO_GOOGLE:
			return {
				...state,
				ssoEmail: payload,
			}

		case REMOVE_DATA:
			return {
				...state,
				data: {
					...state.data,
					parents: state.data.parents.filter((row) => row.id !== payload.id),
				},
			}

		case UPDATE_DATA:
			return {
				...state,
				data: {
					...state.data,
					parents: state.data.parents.map((row) => {
						if (payload.id) {
							return row.id === payload.id ? payload : row
						} else {
							return row._id === payload._id ? payload : row
						}
					}),
				},
			}

		case CREATE_DATA:
			return {
				...state,
				data: {
					...state.data,
					parents: [payload, ...state.data.parents],
				},
				pageActive: 0,
			}

		case SEARCH_DATA:
			json = lib.jsons.filter(action.payload.keyword, state.alldata.parents, action.payload.keys)

			return {
				...state,
				data: { ...state.data, parents: json },
				pageActive: 0,
			}

		case INDEX_DATA:
			return {
				...state,
				alldata: payload,
				data: payload,
				end: 10,
				start: 0,
				itemPerPageActive: 10,
				pageActive: 0,
			}

		case SET_PAGE:
			console.log(action.payload)
			const start = parseFloat(action.payload) * parseFloat(state.itemPerPageActive)
			const end = parseFloat(start) + parseFloat(state.itemPerPageActive)
			return {
				...state,
				pageActive: action.payload,
				start: start,
				end: end,
			}

		case SET_ITEM_PER_PAGE:
			return {
				...state,
				start: 0,
				pageActive: 0,
				end: action.payload,
				itemPerPageActive: action.payload,
			}
		case ERROR_INPUT:
			return {
				...state,
				errors: errors,
			}

		default:
			return state
	}
}

const store = createStore(crud, localStorage.getItem("reduxState") ? JSON.parse(localStorage.getItem("reduxState")) : undefined)

store.subscribe(() => {
	localStorage.setItem("reduxState", JSON.stringify(store.getState()))
})

export default crud
