import React from 'react';
import { useSelector, useDispatch } from "react-redux";
function PaginationList() {
    const datas = useSelector((state) => state.crud)
    const dispatch = useDispatch();
    const lengthdata = datas.filter = datas.data.parents.length;
//    const lengthdata = datas.filter = 'no' ? datas.data.parents.length : datas.datafilter.length;
    const totalPages = Math.ceil(lengthdata / datas.itemPerPageActive);
    const pages = [];

    for (let i = 0; i < totalPages; i++) {
        pages.push(i);
    }


    const onPageChange = id => {
        dispatch({ type: 'SET_PAGE', payload: id })
    }

    return (
        <div>
            <div className="d-flex align-items-end flex-column">
                <div className="btn-group btn-group-sm">
                    {datas.pageActive > 1 ? (
                        <button type="button"
                            onClick={() => onPageChange(0)}
                            className="btn btn-primary border rounded-0">
                            <i className='fa fa-angle-double-left'></i>
                        </button>
                    ) : (
                        <button type="button" disabled
                            className="btn btn-primary border rounded-0"
                        >
                            <i className='fa fa-angle-double-left'></i>
                        </button>
                    )}

                    {datas.pageActive > 0 ? (
                        <button type="button"
                            onClick={() => onPageChange(datas.pageActive - 1)}
                            className="btn btn-primary border rounded-0">
                            Prev
                        </button>
                    ) : (
                        <button type="button" disabled className="btn btn-primary border rounded-0">
                            Prev
                        </button>
                    )}
                    {pages.map(page => (
                        page === datas.pageActive ? (
                            <button key={page} type="button"
                                disabled
                                className="btn btn-primary border rounded-0">
                                {page + 1}
                            </button>) : (
                            <button key={page} type="button"
                                className="btn btn-primary border rounded-0"
                                onClick={() => onPageChange(page)}>
                                {page + 1}
                            </button>
                        )
                    ))}
                    {(datas.pageActive + 1) >= totalPages ? (
                        <button type="button"
                            disabled
                            className="btn btn-primary border rounded-0">
                            Next
                        </button>
                    ) : (
                        <button type="button"
                            onClick={() => onPageChange(datas.pageActive + 1)}
                            className="btn btn-primary border rounded-0">
                            Next
                        </button>
                    )}
                    {(datas.pageActive + 2) >= totalPages ? (
                        <button type="button"
                            disabled
                            className="btn btn-primary border rounded-0">
                            <i className='fa fa-angle-double-right'></i>
                        </button>
                    ) : (
                        <button type="button"
                            onClick={() => onPageChange(totalPages - 1)}
                            className="btn btn-primary border rounded-0">
                            <i className='fa fa-angle-double-right'></i>
                        </button>
                    )}
                </div>
            </div>

        </div>
    );
}

export default PaginationList;
