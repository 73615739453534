import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
const TabTop = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [search, setSearch] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const datas = useSelector((state) => state.crud)
    const dispatch = useDispatch();

    const add_ = () => {
        props.parent_add()
    }

    const reset_ = () => {
        setInputValue('');
        const obj = {
            keyword: '',
            keys: props.keySearch,
        }
        dispatch({ type: 'SEARCH_DATA', payload: obj })
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const obj = {
                keyword: event.target.value,
                keys: props.keySearch,
            }
            if (obj.keyword.length > 0) {
                setSearch(true)
            } else {
                setSearch(false)
            }
            dispatch({ type: 'SEARCH_DATA', payload: obj })
        }
    };

    return (
        <div>
            <div className="container-fluid p-0 border-0 mb-2 mt-1">
                <div className="row justify-content-between">
                    <div className="col-10 col-sm-6  text-right">
                        <div className="input-group input-group-sm">
                            <input type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                className="form-control rounded-0"
                                placeholder="Enter keyword to search" />
                            <div className="input-group-append">
                                {search && (
                                    <button
                                        onClick={reset_}
                                        className="btn btn-danger rounded-0">
                                        <i className="fa fa-times"></i>
                                    </button>
                                )}
                                {!search && (
                                    !props.hideadd && (
                                        <button onClick={add_} className="btn btn-primary rounded-0 mx-1">
                                            <i className="fa fa-plus"></i> Add Data
                                        </button>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1 pl-0 col-2 d-flex align-items-end flex-column">
                        <div className="input-group input-group-sm">
                            {datas.itemPagesList && (
                                <select className="form-control form-control-sm rounded-0"
                                    value={datas.itemPerPageActive}
                                    onChange={(event) => dispatch({ type: 'SET_ITEM_PER_PAGE', payload: event.target.value })}
                                >
                                    {datas.itemPagesList.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabTop;
