import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../../actions/auth";
import $ from 'jquery';
import 'bootstrap';
const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};
const ModalRegister = (props) => {

    if (props.registers) {
        setTimeout(() => {
            $("#modal-register").modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            });
        }, 100)
    } else {
        setTimeout(() => {
            $("#modal-register").modal('hide');
        }, 100)
    }



    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [successful, setSuccessful] = useState(false);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleRegister = (e) => {
        e.preventDefault();

        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(register(username, email, password))
                .then(() => {
                    setSuccessful(true);
                })
                .catch(() => {
                    setSuccessful(false);
                });
        }
    };


    return (
        <div>
            <div className="modal fade" id="modal-register">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content rounded-0 bg-light">
                        <div className="modal-header bg-light shadow-lg border rounded-0 text-muted p-1">
                            <div className="d-flex p-2 w-100 shadow-sm">
                                <div className="p-0 bd-highlight text-muted"><h4 className="m-0">REGISTER</h4></div>
                                <div className="ml-auto p-0 bd-highlight">
                                    <button className="btn p-0 pl-2 pr-2 rounded-0 border-0 btn-outline-dark"
                                        onClick={() => props.set_state_one('registers', false)}
                                    ><h4 className="m-0">&#x2715;</h4>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body bg-white m-0 p-0">
                            <div className="p-3">
                                <img
                                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                                    alt="profile-img"
                                    className="profile-img-card"
                                />
                                <Form onSubmit={handleRegister} ref={form}>
                                    {!successful && (
                                        <div>
                                            <div className="form-group form-group-sm mb-2">
                                                <label className="mb-1 p-0">User ID : </label>
                                                <Input
                                                    type="text"
                                                    className="form-control form-control-sm rounded-0"
                                                    name="username"
                                                    value={username}
                                                    onChange={onChangeUsername}
                                                    validations={[required, vusername]}
                                                />
                                            </div>

                                            <div className="form-group form-group-sm mb-2">
                                                <label className="mb-1 p-0">Password</label>
                                                <Input
                                                    type="password"
                                                    className="form-control form-control-sm rounded-0"
                                                    name="password"
                                                    value={password}
                                                    onChange={onChangePassword}
                                                    validations={[required, vpassword]}
                                                />
                                            </div>

                                            <div className="form-group form-group-sm mb-4">
                                                <label className="mb-1 p-0">Email</label>
                                                <Input
                                                    type="text"
                                                    className="form-control form-control-sm rounded-0"
                                                    name="email"
                                                    value={email}
                                                    onChange={onChangeEmail}
                                                    validations={[required, validEmail]}
                                                />
                                            </div>

                                            <div className="form-group form-group-sm">
                                                <button className="btn btn-sm rounded-0 btn-primary btn-block"><b>Register</b></button>
                                            </div>
                                        </div>
                                    )}

                                    {message && (
                                        <div className="form-group form-group-sm">
                                            <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalRegister;
