import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import clientUrl from "../../clientUrl";
import lib from "../../libs";

const ImagesSingle = (props) => {
    const initialObj = {
        length: 0,
        name: ''
    }
    const [obj, setObj] = useState(initialObj);
    const [image, setImage] = useState();

    const datas = useSelector((state) => state.crud)

    const set_state_one = (key, val) => {
        setObj(prevData => ({
            ...prevData,
            [key]: val,
        }));
    };

    const singleImage = (id) => {
        const imageName = datas.data.childs.upload.find(item => item.id === id);
        return imageName.Name;
    }

    //const thumbnail = datas.data.childs.upload.filter(item => item.Thumbnail === 1 && props.checkThumbnail.includes(item.id));
    /*
    thumbnail.forEach(row => {
        set_state_one('length', thumbnail.length)
        set_state_one('name', row.Name)
        setImage(obj.name)
        return;
    })
    */

    const settoThumbnail = async () => {
        const thumbnail = await datas.data.childs.upload.filter(item => item.Thumbnail === 1 && props.checkThumbnail.includes(item.id));
        thumbnail.forEach(row => {
            set_state_one('length', thumbnail.length)
            set_state_one('name', row.Name)
            setImage(obj.name)

        })
        lib.others.ScrollToTop()
    }
    useEffect(() => {
        settoThumbnail()



    }, [props.checkThumbnail]);

    return (
        <div>
            <div className="d-flex flex-column bg-dark mb-3">
                {obj.length > 0 ? (
                    <div className="p-2 bd-highlight">
                        <img className="img-fluid"
                            src={`${clientUrl.upload}/${props.id}/large/${obj.name}.jpg`}
                            width="100%" height="400" alt="Generic placeholder" />
                    </div>
                ) : (
                    <img className="align-self-center mr-3 rounded-0 border p-0"
                        src={`${clientUrl.upload}/noimage.png`}
                        width="70" height="65" alt="Generic placeholder" />
                )}
                <div className="p-2 bd-highlight">
                    <div className="d-flex flex-row bd-highlight mb-3">
                        {props.checkThumbnail.map(row => (
                            <div key={row} className="p-1 bd-highlight border-0 align-self-center ml-1">
                                <img className="img-fluid btn btn-secondary rounded-0"
                                    src={`${clientUrl.upload}/${props.id}/small/${singleImage(row)}.jpg`}
                                    width="110" height="85" alt="Generic placeholder"
                                    onClick={() => set_state_one('name', singleImage(row))} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagesSingle;
