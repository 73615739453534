
import React, { useState, useEffect, useRef } from "react";



import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import lib from "../../libs";
import { login } from "../../actions/auth";


import $ from 'jquery'; 
import 'bootstrap';
const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const ModalLogin = (props) => {


    const initialState = {
        api: 'user',
        form: {
            googleId: '',
            imageUrl: '',
            email: '',
            name: '',
            givenName: '',
            familyName: '',
        }
    };


    if (props.login) {
        setTimeout(() => {
            $("#modal-login").modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            });
        }, 100)
    } else {
        setTimeout(() => {
            $("#modal-login").modal('hide');
        }, 100)
    }

    let navigate = useNavigate();

    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const [obj, setObj] = useState(initialState);

    //const [userData, setUserData] = useState(null);
    //const [tokensso, setTokensso] = useState();

    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };



    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(login(username, password))
                .then(() => {
                    navigate("/profile");
                    window.location.reload();
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };



    const onSuccess = (res) => {
      //  setTokensso(res.tokenId)
       // setUserData(res.profileObj);
        dispatch({ type: 'SET_SSO_GOOGLE', payload: res })
        props.set_state_one('login', false)
        console.log(JSON.stringify(res.profileObj))
    }

    const onFailure = (res) => {
        console.log("LOGIN FAILED ! res : ", res)
    }



    if (isLoggedIn) {
        return <Navigate to="/profile" />;
    }
    return (
        <div>
            <div className="modal" id="modal-login">
                <div className="modal-dialog modal-sm top-right-div">
                    <div className="modal-content rounded-0 bg-light">
                        <div className="modal-header bg-light shadow-lg border rounded-0 text-muted p-1">
                            <div className="d-flex p-2 w-100 shadow-sm">
                                <div className="p-0 bd-highlight text-muted"><h4 className="m-0">LOGIN</h4></div>
                                <div className="ml-auto p-0 bd-highlight">
                                    <button className="btn p-0 pl-2 pr-2 rounded-0 border-0 btn-outline-dark"
                                        onClick={() => props.set_state_one('login', false)}
                                    ><h4 className="m-0">&#x2715;</h4>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body bg-white m-0 p-0">
                            <div className="p-3">
                                {/* <img
                                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                                    alt="profile-img"
                                    className="profile-img-card"
                                /> */}
                                <Form onSubmit={handleLogin} ref={form}>

                                    <div>
                                        <div className="form-group form-group-sm mb-2">
                                            <label className="mb-1 p-0">User ID : </label>
                                            <Input
                                                type="text"
                                                className="form-control form-control-sm rounded-0"
                                                name="username"
                                                value={username}
                                                onChange={onChangeUsername}
                                                validations={[required]}

                                            />
                                        </div>

                                        <div className="form-group form-group-sm mb-4">
                                            <label className="mb-1 p-0">Password</label>
                                            <Input
                                                type="password"
                                                className="form-control form-control-sm rounded-0"
                                                name="password"
                                                value={password}
                                                onChange={onChangePassword}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-primary rounded-0 btn-block" disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>Login</span>
                                            </button>
                                            {/* {message && (
                                                <div className="form-group rounded-0 p-1">
                                                    <div className="alert alert-danger rounded-0 p-1" role="alert">
                                                        {message}
                                                    </div>
                                                </div>
                                            )} */}
                                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                                        </div>

                                        <div className="form-group full-width">
                                            <GoogleLogin
                                                className="btn btn-block"
                                                clientId={lib.publics.ssoIdClient}
                                                buttonText="Login with Google"
                                                onSuccess={onSuccess}
                                                onFailure={onFailure}
                                                cookiePolicy={'single_host_origin'}
                                                isSignedIn={true}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalLogin;
