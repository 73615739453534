import React, { useState, useEffect } from "react"
import { index } from "../actions/crud"
import { useSelector, useDispatch } from "react-redux"
import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData"
import lib from "../libs"
import FormFilter from "../components/Forms/FormFilter"
import Grids from "../components/GridsList/Grids"
import Lists from "../components/GridsList/Lists"
import PaginationList from "../components/Paginations/PaginationList"

const Home = () => {
	//const { id } = useParams();

	const initialState = {
		title: "Mobil",
		api: "mobil",
		status: "",
		success: "",
		reload: true,
		keySearch: ["Type", "Tahun"],
		form: {
			id: "",
			merk: "",
			Type: "",
			Tahun: "",
			PlatNo: "",
			Warna: "",
			Km: "",
			Harga: "",
			FromRange: "",
			ToRange: "",
			Transmisi: "",
			BahanBakar: "",
			NoRangka: "",
			Jenis: "",
			KapasitasMesin: "",
			NoMesin: "",
			STNK: "",
			BPKB: "",
			FotokopiKTP: "",
			FormA: "",
			MasaSTNK: "",
			Faktur: "",
			KwitansiBlanka: "",
			Keur: "",
			SPH: "",
		},
	}

	const datas = useSelector((state) => state.crud)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [obj, setObj] = useState(initialState)
	const [range, setRange] = useState(false)
	const [grid, setGrid] = useState(true)
	const [cari, setCari] = useState(false)

	const handleInputChange = (key, event) => {
		setRange(false)
		setObj((prevState) => ({
			...prevState,
			form: {
				...prevState.form,
				[key]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
		}))
	}

	const filtering_ = () => {
		//console.log(JSON.stringify(obj.form))

		if (obj.form.FromRange !== "" && obj.form.ToRange === "") {
			setRange(true)
		} else if (obj.form.ToRange !== "" && obj.form.FromRange === "") {
			setRange(true)
		} else if (parseFloat(obj.form.FromRange) > parseFloat(obj.form.ToRange)) {
			setRange(true)
		} else {
			setLoading(false)
			const filters = {
				keyword: obj.form,
			}
			lib.others.ScrollToTop()
			dispatch({ type: "FILTERING", payload: filters })
			setLoading(true)
			setCari(true)
		}
	}

	const reset_ = () => {
		for (const key in initialState.form) {
			delete datas.errors[key]
			setObj((prevState) => ({
				...prevState,
				form: {
					...prevState.form,
					[key]: "",
				},
			}))
		}
	}

	const reload_ = () => {
		reset_()
		setLoading(false)
		dispatch({ type: "RELOADING" })
		setCari(false)
		setLoading(true)
	}

	useEffect(() => {
		dispatch(index(obj.api))
			.then((data) => {
				if (data.success) {
					setLoading(true)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}, [])

	return (
		<div className="border-0">
			{!loading ? (
				<SpinnerFetchingData />
			) : (
				<>
					<div className="container-fluid bg-white p-4">
						<div className="row justify-content-center mb-3">
							<div className="col-lg-3 bg-white text-dark border mb-3">
								<div className="row mb-3 mb-3 mt-3">
									<div className="col-lg-12">
										<div className="section-title">
											<h2 className="title">Filter</h2>
										</div>
									</div>
								</div>

								<div className="row mb-3">
									<div className="col-lg-12">
										<FormFilter handleInputChange={handleInputChange} labelName="Merk" keyName="merk" keyValue={obj.form.merk} typeForm="selectapi" arr={datas.data.childs.merk} fields="name" />
										<FormFilter handleInputChange={handleInputChange} labelName="Type" keyName="Type" keyValue={obj.form.Type} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} labelName="Tahun" keyName="Tahun" keyValue={obj.form.Tahun} typeForm="selectstatic" lists={lib.jsonStatic.years} />
										<FormFilter handleInputChange={handleInputChange} labelName="Plat No" keyName="PlatNo" keyValue={obj.form.PlatNo} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} labelName="Warna" keyName="Warna" keyValue={obj.form.Warna} typeForm="selectstatic" lists={lib.jsonStatic.colors} />
										<FormFilter handleInputChange={handleInputChange} labelName="Km" keyName="Km" keyValue={obj.form.Km} typeForm="number" />
										<FormFilter handleInputChange={handleInputChange} eror={range} labelName="Range Harga" keyName="FromRange" ToRange="ToRange" keyValue={obj.form.FromRange} keyValueToRange={obj.form.ToRange} typeForm="range" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Transimisi" keyName="Transmisi" keyValue={obj.form.Transmisi} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Bahan Bakar" keyName="BahanBakar" keyValue={obj.form.BahanBakar} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Nomor Rangka" keyName="NoRangka" keyValue={obj.form.NoRangka} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Jenis" keyName="Jenis" keyValue={obj.form.Jenis} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Kapasitas Mesin (cc)" keyName="KapasitasMesin" keyValue={obj.form.KapasitasMesin} typeForm="number" col="3" setmaxLength={6} />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Nomor Mesin" keyName="NoMesin" keyValue={obj.form.NoMesin} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="STNK" keyName="STNK" keyValue={obj.form.STNK} typeForm="boolean" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="BPKB" keyName="BPKB" keyValue={obj.form.BPKB} typeForm="number" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Fotokopi KTP" keyName="FotokopiKTP" keyValue={obj.form.FotokopiKTP} typeForm="boolean" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Form A" keyName="FormA" keyValue={obj.form.FormA} typeForm="boolean" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Faktur" keyName="Faktur" keyValue={obj.form.Faktur} typeForm="boolean" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="Kwitansi Blanka" keyName="KwitansiBlanka" keyValue={obj.form.KwitansiBlanka} typeForm="boolean" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="KEUR" keyName="Keur" keyValue={obj.form.Keur} typeForm="text" />
										<FormFilter handleInputChange={handleInputChange} eror={datas.errors} labelName="SPH" keyName="SPH" keyValue={obj.form.SPH} typeForm="text" />
										<br />
										<button className="btn rounded-pill btn-block btn-secondary" onClick={() => filtering_()}>
											{" "}
											Search{" "}
										</button>
									</div>
								</div>
							</div>
							<div className="col-lg-9 bg-light border border-left-0">
								<div className="row justify-content-end mb-1 mt-3">
									<div className="col-lg-12">
										<div className="section-title text-right">
											<h2 className="title">Products</h2>
										</div>
									</div>
									<div className="col-lg-12 p-0 m-0">
										<div className="d-flex p-3">
											<div className="p-0 flex-grow-1">
												{cari && (
													<div className="btn-group btn-group-sm">
														<button type="button" disabled className="btn border-0 text-info rounded-0 mx-1" onClick={() => setGrid(true)}>
															Data Ditemukan : <b className="text-muted">{datas.data.parents.length}</b> Record
														</button>
														<button type="button" className="btn btn-outline-primary border-0 rounded-0" onClick={reload_}>
															Refresh
														</button>
													</div>
												)}
											</div>
											<div className="p-0">
												<div className="btn-group btn-group-sm">
													<button type="button" className="btn btn-outline-primary border rounded-0 mx-1" onClick={() => setGrid(true)}>
														<i className="fa fa-list"></i>
													</button>
													<button type="button" className="btn btn-outline-success border rounded-0" onClick={() => setGrid(false)}>
														<i className="fa fa-bars"></i>
													</button>
												</div>
											</div>
											<div className="p-0 ml-1">
												<select className="form-control form-control-sm rounded-0" value={datas.itemPerPageActive} onChange={(event) => dispatch({ type: "SET_ITEM_PER_PAGE", payload: event.target.value })}>
													{datas.itemPagesList.map((option, index) => (
														<option key={index} value={option}>
															{option}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>

								{grid ? <Grids datagrid={datas.data.parents} /> : <Lists datagrid={datas.data.parents} />}

								<div className="row">
									<div className="col-12 mb-4 text-center">
										<PaginationList />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default Home
