import http from "../http-common";
//findone

const upload = async (id, file) => {
  return await http.post("/upload/" + id, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const show = async (obj) => {
  //console.log(JSON.stringify(obj))
  return await http.get("/" + obj.api + '/' + obj.id);
}

const create = async (obj) => {
  return await http.post("/" + obj.api, obj.form);
};

const remove = async (obj) => {
  return await http.delete("/" + obj.api + "/" + obj.form.id);
};

const update = async (obj) => {
  return await http.put("/" + obj.api, obj.form);
};

const index = async (api) => {
  return await http.get("/" + api);
}


const fileupload = async (id, file) => {
  return await http.post("/faktur/" + id, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


const crud = {
  create,
  index,
  update,
  remove,
  upload,
  show,
  fileupload
};

export default crud;