import React from "react";


const Dashboards = () => {
    return (
        <div>
            <button className="btn btn-sm border-0" type="button" disabled>
asd
            </button>
        </div>
    );
};
export default Dashboards;
