import React from "react"

const FormFilter = (props) => {
	const classnames = "form-control form-control-sm rounded-0"
	const classnamesRange = props.eror ? "form-control form-control-sm rounded-0 is-invalid" : "form-control form-control-sm rounded-0"
	const handleInputChange = (key, event) => {
		props.handleInputChange(key, event)
	}

	return (
		<div>
			<form>
				{props.typeForm === "selectapi" && (
					<div className="form-group form-group-sm mb-3">
						<label>{props.labelName}</label>
						<select className={classnames} value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)}>
							<option value="">....</option>
							{props.arr.map((row) => (
								<option value={row._id} key={row._id}>
									{row[props.fields]}
								</option>
							))}
						</select>
					</div>
				)}

				{props.typeForm === "selectstatic" && (
					<div className="form-group form-group-sm mb-3">
						<label>{props.labelName}</label>
						<select className={classnames} value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)}>
							<option value="">....</option>

							{props.lists.map((option, index) => (
								<option key={index} value={option}>
									{option}
								</option>
							))}
						</select>
					</div>
				)}

				{props.typeForm === "text" && (
					<div className="form-group form-group-sm mb-3">
						<label>{props.labelName}</label>
						<input type="text" value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)} className={classnames} />
					</div>
				)}

				{props.typeForm === "number" && (
					<div className="form-group form-group-sm mb-3">
						<label>{props.labelName}</label>
						<input
							type="text"
							value={props.keyValue}
							onChange={(event) => handleInputChange(props.keyName, event)}
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
									event.preventDefault()
								}
							}}
							className={classnames}
							maxLength={props.setmaxLength}
						/>
					</div>
				)}

				{props.typeForm === "boolean" && (
					<div className={`form-group form-group-sm mb-3`}>
						<label>{props.labelName}</label>
						<input type="checkbox" value={true} checked={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)} className={"col-1"} />
					</div>
				)}

				{props.typeForm === "date" && (
					<div className={`form-group form-group-sm mb-3`}>
						<label>{props.labelName}</label>
						<input type="date" value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)} className={classnames} />
					</div>
				)}

				{props.typeForm === "range" && (
					<div className="form-group form-group-sm mb-3">
						<label>{props.labelName}</label>
						<div className="row">
							<div className="col">
								<input
									type="text"
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
											event.preventDefault()
										}
									}}
									value={props.keyValue}
									onChange={(event) => handleInputChange(props.keyName, event)}
									className={classnamesRange}
								/>
							</div>
							<div className="col">
								<input
									type="text"
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
											event.preventDefault()
										}
									}}
									value={props.keyValueToRange}
									onChange={(event) => handleInputChange(props.ToRange, event)}
									className={classnamesRange}
								/>
							</div>
						</div>
						{props.eror && (
							<div className="row mt-1">
								<div className="col">
									<div className="text-danger">Penulisan range salah..</div>
								</div>
							</div>
						)}
					</div>
				)}
			</form>
		</div>
	)
}

export default FormFilter
