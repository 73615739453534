const className={};
className.table = {
    style : 'table table-sm table-hover m-0 table-bordered table-striped',
    parent : 'table table-sm table-hover m-0 table-bordered',
    th: 'bg-secondary text-light border-top-0 border-bottom-0 border-left p-1',
    td: 'p-0 border-right-0'
}
className.icon = {
    edit : 'fa fa-pencil',
    remove : 'fa fa-trash',
    image : 'fa fa-image'
}
export default className;