import React, { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { update, show, remove, addImage } from "../actions/crud"

import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData"
import clientUrl from "../clientUrl"
import lib from "../libs"
import ModalSuccess from "../components/Modals/ModalSuccess"
import TabTitle from "../components/TabBarComponents/TabTitle"
import ModalConfirm from "../components/Modals/ModalConfirm"

const Gallery = () => {
	const inputFile = {
		display: "none",
	}
	const initialState = {
		api: "upload",
		status: "",
		success: "",
		form: {
			id: "",
		},
	}

	const { id } = useParams()
	const fileInputRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const [obj, setObj] = useState(initialState)
	const datas = useSelector((state) => state.crud)
	const dispatch = useDispatch()
	useEffect(() => {
		const params = {
			api: obj.api,
			id: id,
		}
		dispatch(show(params))
			.then((data) => {
				if (data.success) {
					setLoading(true)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}, [])

	const upload = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}
	const handleFileChange = async (event) => {
		const currentDate = new Date()
		const formattedDate = currentDate.toISOString().replace(/[:T.-]/g, "")
		const selectedFile = event.target.files[0]
		const formData = new FormData()
		formData.append("file", selectedFile)
		const customName = id + "__" + formattedDate

		dispatch(addImage(customName, formData))
			.then((data) => {
				if (data.success) {
					successfull("saved")
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}

	const successfull = (msg) => {
		set_state_one("status", "")
		set_state_one("success", msg)
	}

	const set_state_one = (key, val) => {
		setObj((prevData) => ({
			...prevData,
			[key]: val,
		}))
	}
	const set_state_two = (key, val) => {
		setObj((prevData) => ({
			...prevData,
			form: {
				...prevData.form,
				[key]: val,
			},
		}))
	}

	const reset_ = () => {
		for (const key in initialState.form) {
			delete datas.errors[key]
			setObj((prevState) => ({
				...prevState,
				form: {
					...prevState.form,
					[key]: "",
				},
			}))
		}
	}

	const deleteImage = () => {
		dispatch(remove(obj))
			.then((data) => {
				if (data.success) {
					successfull("remove image")
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}
	const remove_ = (idx) => {
		reset_()
		set_state_one("status", "remove")
		set_state_two("id", idx + "__" + id)
		/*
        const params = {
            api: obj.api,
            form: {
                id: idx
            }
        }
        dispatch(remove(params))
            .then(data => {
                if (data.success) {
                    successfull("remove image")
                    window.location.reload()
                }
            })
            .catch(e => {
                console.log(e);
            });
            */
	}

	const setThumb = (idx) => {
		const params = {
			api: obj.api,
			form: {
				id: idx,
			},
		}
		dispatch(update(params))
			.then((data) => {
				if (data.success) {
					successfull("Updated thumbnail")
					window.location.reload()
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}

	// console.log(JSON.stringify(datas.data.parents))
	/*

        const initialState = {
            api: 'upload',
            modal: false,
            status: '',
            success: '',
            reload: true,
            form: {
                id: '',
                categoryname: ''
            }
        };
    
        const { id } = useParams();
        const fileInputRef = useRef(null);
        const [loading, setLoading] = useState(false);
        const [obj, setObj] = useState(initialState);
        const datas = useSelector((state) => state.crud)
        const dispatch = useDispatch();
    
        useEffect(() => {
            const params = {
                api: obj.api,
                id: id
            }
            dispatch(show(params))
                .then(data => {
                    if (data.success) {
                        setLoading(true)
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }, []);
    
    
    
    
        */
	//dispatch(selectOne(id));
	//datas.data.childs.mobil.uploads
	return (
		<div>
			<TabTitle title="Images Mobil" />

			<div className="tab-content border border-top-0 p-2 bg-white">
				<div className="tab-pane fade show active">
					{!loading ? (
						<SpinnerFetchingData />
					) : (
						<>
							<div className="container-fluid border-0 p-2">
								<div className="row justify-content-between mb-3">
									<div className="col-12 border-0">
										<div className="d-flex mb-0 p-0">
											<div className="">
												<div>
													<span className="text-muted">Merk</span>
													<span className="ml-1"> : {datas.data.childs.mobil.merk.name}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Plat No</span>
													<span className="ml-1"> : {datas.data.childs.mobil.PlatNo}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div className="">
												<div>
													<span className="text-muted">Type</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Type}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Km</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Km}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div className="">
												<div>
													<span className="text-muted">Warna</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Warna}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Tahun</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Tahun}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">ID Mobil</span>
													<span className="ml-1"> : {datas.data.childs.mobil.id}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Harga</span>
													<span className="ml-1"> : {lib.formatter.formatCurrencyIDR(datas.data.childs.mobil.Harga)}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">Transmisi</span>
													<span className="ml-1"> : {datas.data.childs.mobil.transmisi}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Bahan Bakar</span>
													<span className="ml-1"> : {datas.data.childs.mobil.BahanBakar}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">Nomor Rangka</span>
													<span className="ml-1"> : {datas.data.childs.mobil.NoRangka}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Jenis</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Jenis}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">Kapasitas Mesin</span>
													<span className="ml-1"> : {`${datas.data.childs.mobil.KapasitasMesin} cc`}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Nomor Mesin</span>
													<span className="ml-1"> : {datas.data.childs.mobil.NoMesin}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">STNK</span>
													<span className="ml-1"> : {datas.data.childs.mobil.STNK ? "ADA" : "TIDAK ADA"}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">BPKB</span>
													<span className="ml-1"> : {`${datas.data.childs.mobil.BPKB} HARI KERJA`}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">Fotokopi KTP</span>
													<span className="ml-1"> : {datas.data.childs.mobil.FotokopiKTP ? "ADA" : "TIDAK ADA"}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Form A</span>
													<span className="ml-1"> : {datas.data.childs.mobil.FormA ? "ADA" : "TIDAK ADA"}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">Masa STNK</span>
													<span className="ml-1"> : {datas.data.childs.mobil.MasaSTNK}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">Faktur</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Faktur ? "ADA" : "TIDAK ADA"}</span>
												</div>
											</div>
										</div>

										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">Kwitansi Blanka</span>
													<span className="ml-1"> : {datas.data.childs.mobil.KwitansiBlanka ? "ADA" : "TIDAK ADA"}</span>
												</div>
											</div>
											<div className="ml-auto">
												<div>
													<span className="text-muted">KEUR</span>
													<span className="ml-1"> : {datas.data.childs.mobil.Keur}</span>
												</div>
											</div>
										</div>
										<div className="d-flex mb-0 p-0">
											<div>
												<div>
													<span className="text-muted">SPH</span>
													<span className="ml-1"> : {datas.data.childs.mobil.SPH}</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									{datas.data.parents.map((row, no) => (
										<div className="col-4 col-sm-2 text-center" key={no}>
											<div className="border rounded-0">
												<div>
													<img className="align-self-center rounded-0 p-1" src={`${clientUrl.upload}/${id}/medium/${row.Name}.jpg`} width="100%" height="100" alt={row} />
												</div>
												<div className="border-top">
													<div className="d-flex p-0">
														{row.Thumbnail == 0 ? (
															<div className="ml-1 btn btn-sm p-0 btn-outline-primary pl-1 pr-1 border-0 rounded-0" onClick={() => setThumb(row.id)}>
																<i className="fa fa-circle"></i>
															</div>
														) : (
															<div className="ml-1 p-0 pl-1 pr-1 border-0 rounded-0">
																<i className="fa fa-check-circle text-success"></i> Thumb
															</div>
														)}
														<div className="ml-auto btn btn-sm btn-outline-danger border-0 rounded-0 p-0 mr-1 pl-1 pr-1" onClick={() => remove_(row.id)}>
															<i className="fa fa-times"></i>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
									<div className="col-4 col-sm-2 text-center">
										<input type="file" ref={fileInputRef} style={inputFile} onChange={handleFileChange} />
										<img className="align-self-center btn rounded-0 border p-1" src={`${clientUrl.upload}/images.png`} onClick={() => upload()} width="100%" height="125" />
									</div>
								</div>
							</div>

							<ModalSuccess success={obj.success} set_state_one={set_state_one} />

							<ModalConfirm childPost={deleteImage} status={obj.status} teks="Image" />
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default Gallery
