import moment from 'moment';
const formatter={
    formatCurrencyIDR(amount){
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          currencyDisplay: 'symbol',
          minimumFractionDigits: 0,
        }).format(amount);
      },

      formatDate(dates){
        return moment(dates).format('DD MMM YYYY');
      }
};

export default formatter;