import React from "react";

import $ from 'jquery'; 

const ModalConfirm = (props) => {
    //console.log(props.teks.success)
    if (props.status === 'remove') {
        setTimeout(() => {
            $("#modal-confirm").modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            });
        }, 100)
    } else {
        setTimeout(() => {
            $("#modal-confirm").modal('hide');
        }, 100)
    }

    const cancel_ = () => {
        setTimeout(() => {
            $("#modal-confirm").modal('hide');
        }, 100)
    }
   

    const post_ = () => {
        props.childPost();
    };
    return (
        <div>
            <div className="modal fade" id="modal-confirm">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0 bg-light">
                        <div className="modal-header bg-light text-muted p-2">
                            <div className="container p-0">
                                <div className="row">
                                    <div className="col-12 col-sm-12 text-center">
                                        <button type="button" className="btn rounded-0 m-0 btn-light disabled text-danger">
                                            <strong> CONFIRM REMOVE DATA </strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body text-center bg-light m-0">
                            <div>
                                <span className="text-info"> asd</span><br />
                                <strong className="text-muted m-0">Do you want to remove this record ?
                                </strong>

                            </div>
                        </div>

                        <div className="modal-footer bg-light p-1 justify-content-center">
                            <button type="button" onClick={post_} className="btn btn-primary btn-sm rounded-0 border-0">Remove</button>
                            <button type="button" onClick={cancel_} className="btn btn-secondary btn-sm rounded-0 border-0">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalConfirm;
