

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";


import "bootstrap/dist/css/bootstrap.min.css";

import logo from './assets/img/user1.png';
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/checkanimation.scss"
import Profile from "./components/Profile";
import Merk from "./pages/Merk";
import Mobil from "./pages/Mobil";
import Gallery from "./pages/Gallery";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import EventBus from "./common/EventBus";
import Detail from "./pages/Detail";
import NavBars from "./components/NavBars";
import ModalLogin from "./components/Modals/ModalLogin";
import ModalRegister from "./components/Modals/ModalRegister";
import Dashboards from "./pages/Dashboards";
import Home from "./pages/Home";
import User from "./pages/User";
import Faktur from "./pages/Faktur";
import ekr from './assets/img/bg-masthead.jpg';
//import Das
import $ from 'jquery';
function App() {

  const initialState = {
    login: false,
    registers: false,
  };
  // eslint-disable-next-line
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // eslint-disable-next-line
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [obj, setObj] = useState(initialState);

  let location = useLocation();

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {

    $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
      //$(".ok").hide()
    });

    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const set_state_one = (key, val) => {
    console.log(key)
    setObj(prevData => ({
      ...prevData,
      [key]: val,
    }));
  };
  const openLogin = () => {
    set_state_one('registers', false)
    set_state_one('login', true)
  }

  const openRegister = () => {
    set_state_one('login', false)
    set_state_one('registers', true)
  }



  return (
    <div className="App">
      {currentUser ? (
        <div className="wrapper">
          <nav id="sidebar" className="sticky-top bg-white text-muted border-right">
            <div className="sticky-top">
              <ul className="list-unstyled text-dark">

                <li class="sidebar-brand"><a href="#page-top">
                  <img src={ekr} width={50}/>
                  <br/>
                  <span>Elektrindo Kreasi Raya</span></a>
                </li>
                <li className="border-bottom">
                  <Link to={"/profile"} className="nav-link">
                    <i className='fa fa-home text-primary'></i> Home
                  </Link>
                </li>

                <li className="border-bottom">
                  <Link to={"/merk"} className="nav-link">
                    <i className='fa fa-list text-primary'></i> Merk Mobil
                  </Link>
                </li>

                <li className="border-bottom">
                  <Link to={"/mobil"} className="nav-link">
                    <i className='fa fa-car text-primary'></i> Mobil
                  </Link>
                </li>


                <li className="border-bottom">
                  <Link to={"/user"} className="nav-link">
                    <i className='fa fa-users text-primary'></i> Users
                  </Link>
                </li>


                <li className="border-bottom">
                  <Link to={"/faktur"} className="nav-link">
                    <i className='fa fa-file text-primary'></i> Fakturs
                  </Link>
                </li>

                <li className="border-bottom">
                  <a href="/" className="nav-link" onClick={logOut}>
                    <i className='fa fa-sign-out text-primary'></i> LogOut
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div id="content" className="p-0">
            <nav className="navbar navbar-expand-lg p-2 rounded-0 navbar-white bg-white sticky-top shadow-sm mb-2">
              <div className="container-fluid">
                <div className="navbar-header">
                  <button type="button" id="sidebarCollapse" className="navbar-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <div className="navbar-header pull-right">
                  <img className="rounded-pill" src={logo} alt="ini images" />
                </div>
              </div>
            </nav>

            <div className="container mt-3">
              <Routes>
                <Route path="/dashboard" element={<Dashboards />} />
                <Route path="/user" element={<User />} />
                <Route path="/faktur" element={<Faktur />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/gallery/:id" element={<Gallery />} />
                <Route path="/merk" element={<Merk />} />
                <Route path="/mobil" element={<Mobil />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (

        <div>
          <header>
            <NavBars openLogin={openLogin} openRegister={openRegister} />
          </header>
          <main role="main" className="p-1 border-0">
            <div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/:id" element={<Detail />} />
              </Routes>
            </div>
            <footer className="container-fluid border-top">
              <p className="float-right">Back to top</p>
              <p>Copyright &copy; Elektrindo Kreasi Raya 2023</p>
            </footer>
            <ModalLogin
              login={obj.login}
              set_state_one={set_state_one}
            />
{/* 
            <ModalRegister
              registers={obj.registers}
              set_state_one={set_state_one}
            /> */}
          </main>
        </div>

      )}
    </div>
  );
}

export default App