const jsons = {
	filtering(keyword, obj) {
		var filteredData = obj
		keyword.Harga = keyword.FromRange + "-" + keyword.ToRange

		console.log(JSON.stringify(keyword))

		if (keyword.merk !== "") {
			filteredData = filteredData.filter((item) => item.merk._id === keyword.merk.trim())
		}
		if (keyword.Tahun !== "") {
			filteredData = filteredData.filter((item) => item.Tahun === keyword.Tahun.trim())
		}
		if (keyword.Warna !== "") {
			filteredData = filteredData.filter((item) => item.Warna === keyword.Warna.trim())
		}

		if (keyword.Km !== "") {
			filteredData = filteredData.filter((item) => item.Km === keyword.Km.trim())
		}

		if (keyword.FromRange !== "" && keyword.ToRange !== "") {
			filteredData = filteredData.filter((item) => item.Harga >= keyword.FromRange.trim() && item.Harga <= keyword.ToRange.trim())
		}

		if (keyword.Type !== "") {
			var regex = new RegExp(keyword.Type.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.Transmisi !== "") {
			var regex = new RegExp(keyword.Transmisi.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.BahanBakar !== "") {
			var regex = new RegExp(keyword.BahanBakar.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.NoRangka !== "") {
			var regex = new RegExp(keyword.NoRangka.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.Jenis !== "") {
			var regex = new RegExp(keyword.Jenis.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.KapasitasMesin !== "") {
			filteredData = filteredData.filter((item) => item.KapasitasMesin === keyword.KapasitasMesin.trim())
		}

		if (keyword.NoMesin !== "") {
			var regex = new RegExp(keyword.NoMesin.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.STNK !== "") {
			filteredData = filteredData.filter((item) => item.STNK === keyword.STNK)
		}

		if (keyword.BPKB !== "") {
			filteredData = filteredData.filter((item) => item.BPKB === keyword.BPKB.trim())
		}

		if (keyword.FotokopiKTP !== "") {
			filteredData = filteredData.filter((item) => item.FotokopiKTP === keyword.FotokopiKTP)
		}

		if (keyword.FormA !== "") {
			filteredData = filteredData.filter((item) => item.FormA === keyword.FormA)
		}

		if (keyword.Faktur !== "") {
			filteredData = filteredData.filter((item) => item.Faktur === keyword.Faktur)
		}

		if (keyword.KwitansiBlanka !== "") {
			filteredData = filteredData.filter((item) => item.KwitansiBlanka === keyword.KwitansiBlanka)
		}

		if (keyword.Keur !== "") {
			var regex = new RegExp(keyword.Keur.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.SPH !== "") {
			var regex = new RegExp(keyword.SPH.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.Type.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		if (keyword.PlatNo !== "") {
			var regex = new RegExp(keyword.PlatNo.trim(), "i")
			var tmp = []
			filteredData.forEach((row) => {
				if (row.PlatNo.search(regex) !== -1) {
					tmp.push(row)
				}
			})
			filteredData = tmp
		}

		return filteredData
	},

	filter(keyword, obj, keys) {
		const str = keyword.trim()
		var tmp = []
		var regex = new RegExp(str, "i")
		obj.forEach((row) => {
			if (row[keys[0]].search(regex) !== -1 || row[keys[1]].search(regex) !== -1) {
				tmp.push(row)
			}
		})
		return tmp
	},
}

export default jsons
