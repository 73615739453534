import React from "react";
import { useSelector } from "react-redux";
import ImagesList from "../Images/ImagesList";
import { useNavigate } from "react-router-dom";
import lib from "../../libs";

const Lists = (props) => {
    const navigate = useNavigate();
    const datas = useSelector((state) => state.crud)
    return (
        <div>
            <div className="row">
                <div className="col-12 col-sm-12 border-top">
                    {props.datagrid.slice(datas.start, datas.end).map((row, no) => (
                        <div className="media mb-1 border-bottom p-1" key={row.id}>
                            <ImagesList id={row.id} checkThumbnail={row.uploads} />
                            <div className="media-body">
                                <b className="mt-0 text-muted">{row.merk.name} </b> <span className="text-info">{row.Type}</span><br />
                                <span className="text-muted">Tahun : </span> <span>{row.Tahun} </span>,&nbsp;
                                <span className="text-muted">Plat No : </span> <span>{row.PlatNo} </span>,&nbsp;
                                <span className="text-muted">Warna : </span> <span>{row.Warna} </span>,&nbsp;
                                <span className="text-muted">Km : </span> <span>{row.Km} </span><br />
                                <div className="d-flex">
                                    <div>
                                        <span className="text-muted">Harga : </span>
                                        <span className="text-danger">&nbsp;{lib.formatter.formatCurrencyIDR(row.Harga)}</span>
                                    </div>
                                    <div className="ml-auto">
                                    <button className="btn rounded-pill w-100 pl-3 pr-3 btn-sm btn-outline-primary" onClick={() =>  navigate(`/${row.id}`)}>Detail</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Lists;
