import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fileupload } from "../../actions/crud";
import ModalSuccess from "./ModalSuccess";
import SpinnerFetchingData from "../Spinners/SpinnerFetchingData";

import $ from 'jquery';
const ModalUploadFaktur = (props) => {
    const inputFile = {
        display: 'none',
    };

    //idmob
    const initialState = {
        status: '',
        success: '',
    };
    const [loading, setLoading] = useState(false);
    const [obj, setObj] = useState(initialState);
    const datas = useSelector((state) => state.crud)
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    if (props.status === 'faktur') {
        //setLoading(false)
        setTimeout(() => {
            $("#modal-faktur").modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            });
        }, 100)
    } else {
        setTimeout(() => {
            $("#modal-faktur").modal('hide');
        }, 100)
    }
    const set_state_one = (key, val) => {
        setObj(prevData => ({
            ...prevData,
            [key]: val,
        }));
    };

    const successfull = (msg) => {
        set_state_one('status', '')
        set_state_one('success', msg)
    }

    const uploadfile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }
    // {datas.ssoEmail.profileObj ? (
    const handleFileChange = async (event) => {
        setLoading(true)
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace(/[:T.-]/g, '');
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        const customName = datas.ssoEmail.profileObj.email + '__' + formattedDate + '__' + props.idmob;
        dispatch(fileupload(customName, formData))
            .then(data => {
                if (data.success) {
                    successfull("file uploaded")                   
                    setLoading(false)
                    props.set_state_one('status', '')
                }
            })
            .catch(e => {
                console.log(e);
            });
    };
    return (
        <div>
            <div className="modal fade" id="modal-faktur">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0 bg-light">
                        <div className="modal-header bg-light text-muted p-2">
                            <div className="container p-0">
                                <div className="row">
                                    <div className="col-12 col-sm-12 text-center">
                                        <button type="button" className="btn rounded-0 m-0 btn-light disabled text-dark">
                                            <strong> UPLOAD FAKTUR </strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body text-center bg-light m-0">
                            <div>
                                <span className="text-info"> {props.teks}</span><br />
                                <strong className="text-muted m-0">Silahkan Upload Bukti Faktur Anda..
                                </strong>
                            </div>
                        </div>

                        <div className="modal-footer bg-light p-1 justify-content-center">

                            {loading ? (
                                <SpinnerFetchingData />
                            ) : (
                                <>
                                    <input type="file" ref={fileInputRef} style={inputFile} onChange={handleFileChange} />
                                    <button type="button" onClick={uploadfile} className="btn btn-primary btn-sm rounded-0 border-0">Browse File</button>
                                    <button type="button" onClick={() => props.set_state_one('status', '')} className="btn btn-secondary btn-sm rounded-0 border-0">Cancel</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ModalSuccess
                success={obj.success}
                set_state_one={set_state_one}
            />
        </div>
    );
};

export default ModalUploadFaktur;
