import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { index } from "../actions/crud";

import PaginationList from "../components/Paginations/PaginationList";
import TabTop from "../components/TabBarComponents/TabTop";
import TabTitle from '../components/TabBarComponents/TabTitle';
import lib from "../libs";
import clientUrl from "../clientUrl";
import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData";

const Faktur = () => {
  const initialState = {
    title: 'Faktur',
    api: 'faktur',
    modal: false,
    status: '',
    success: '',
    reload: true,
    keySearch: ['email', 'id'],
    form: {
      _id: '',
      status: '',
      email: '',
    }
  };

  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState(initialState);
  const datas = useSelector((state) => state.crud)
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(index(obj.api))
      .then(data => {
        if (data.success) {
          setLoading(true)
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);



  //removeNestedKey
  const add_ = () => {
    // post_();
  }




  const download = async (id) => {

    const response = await fetch(`${clientUrl.server}/fakturs/${id}`);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = id;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }




  return (
    <div>
      <TabTitle title={obj.title} />
      <div className="tab-content border border-top-0 p-2 bg-white">
        <div className="tab-pane fade show active">
          {!loading ? (
            <SpinnerFetchingData />
          ) : (
            <>
              <div className="container-fluid border-0 p-2">
                <div className="row justify-content-between">
                  <div className="col-12 col-sm-12">
                    <TabTop parent_add={add_} keySearch={obj.keySearch} hideadd={true} />
                    <div className="table-responsive p-0 mb-2">
                      <table className={`${lib.className.table.style}`}>
                        <thead>
                          <tr>
                            <th width="6%" className={`${lib.className.table.th} text-center`}>No.</th>
                            <th className={`${lib.className.table.th} text-center`}>Date</th>
                            <th className={`${lib.className.table.th}`}>Email</th>
                            <th className={`${lib.className.table.th}`}>Mobil</th>
                            <th className={`${lib.className.table.th} text-center bg-white border-0`}>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>

                          {datas.data.parents.slice(datas.start, datas.end).map((row, no) => (
                            <tr className="text-muted" key={no}>
                              {/* 
                              <td className="text-center p-0 border-left-0">
                                {row.status > 0 ? (
                                  <button className="btn btn-sm btn-outline-danger rounded-0 p-0 pl-1 pr-1"
                                    onClick={() => edit_(row._id)}>
                                    Cancel
                                  </button>
                                ) : (
                                  <button className="btn btn-sm btn-outline-success  rounded-0 p-0 pl-1 pr-1"
                                    onClick={() => edit_(row._id)}>
                                    Approval
                                  </button>
                                )}
                              </td>
                              */}
                              <td className={`${lib.className.table.td} text-center`}>{no + (datas.start + 1)} .</td>
                              <td className={`${lib.className.table.td} text-center text-dark`}> {lib.formatter.formatDate(row.createdAt)}</td>
                              <td className={`${lib.className.table.td}`}>&nbsp;{row.Email} </td>
                              <td className={`${lib.className.table.td} text-dark`}>&nbsp;{`${row.mobil.Type}, ${row.mobil.PlatNo}, ${lib.formatter.formatCurrencyIDR(row.mobil.Harga)}`}</td>
                              <td className={`${lib.className.table.td} text-center`}>
                                <button className="btn btn-sm btn-outline-success border-0 rounded-0 p-0 m-0 pl-1 pr-1"
                                  onClick={() => download(row.Filename + '.' + row.Extension)}>
                                  <small><i className="fa fa-download"></i> Download</small>
                                </button>
                                  
                              </td>

                              {/* 
                              {row.status > 0 ? (
                                <td className={`${lib.className.table.td} text-success text-center`}><i className="fa fa-check-circle"></i> Approved</td>
                              ) : (
                                <td className={`${lib.className.table.td} text-danger text-center`}><i className="fa fa-exclamation-circle"></i> Pending</td>
                              )}
                              */}
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <PaginationList
              />

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faktur;

