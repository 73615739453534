import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import clientUrl from "../../clientUrl";

const ImagesInside = (props) => {
    const initialObj = {
        length: 0,
        name: ''
    }
    const [obj, setObj] = useState(initialObj);

    const datas = useSelector((state) => state.crud)

    const set_state_one = (key, val) => {
        setObj(prevData => ({
            ...prevData,
            [key]: val,
        }));
    };

    useEffect(() => {
        const thumbnail = datas.data.childs.upload.filter(item => item.Thumbnail === 1 && props.checkThumbnail.includes(item.id));
        thumbnail.forEach(row => {
            set_state_one('length', thumbnail.length)
            set_state_one('name', row.Name)
            // setLoading(true)
        })
    }, []);



    return (
        <div>
            {obj.length > 0 ? (
                <img className="align-self-center mr-3 rounded-0 border p-0"
                    src={`${clientUrl.upload}/${props.id}/medium/${obj.name}.jpg`}
                    width="70" height="65" alt="Generic placeholder image" />
            ) : (
                <img className="align-self-center mr-3 rounded-0 border p-0"
                    src={`${clientUrl.upload}/noimage.png`}
                    width="70" height="65" alt="Generic placeholder image" />
            )}
        </div>
    );
};

export default ImagesInside;
