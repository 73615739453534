import React from "react"

const FormInput = (props) => {
	//console.log(JSON.stringify(props.lists))
	const classnames = {
		form: props.eror[props.keyName] ? "form-control form-control-sm rounded-0 is-invalid" : "form-control form-control-sm rounded-0",
	}

	const handleInputChange = (key, event) => {
		props.handleInputChange(key, event)
	}

	return (
		<div>
			<div className="form-group row mb-1">
				<div className="col-4 col-sm-3 col-form-label">{props.labelName}</div>

				{props.typeForm === "selectapi" && (
					<div className={`col-8 col-sm-${props.col}`}>
						<select className={classnames.form} value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)}>
							<option value="">....</option>
							{props.arr.map((row) => (
								<option value={row._id} key={row._id}>
									{row[props.fields]}
								</option>
							))}
						</select>
						<div className="invalid-feedback">{props.eror[props.keyName]}</div>
					</div>
				)}

				{props.typeForm === "selectstatic" && (
					<div className={`col-8 col-sm-${props.col}`}>
						<select className={classnames.form} value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)}>
							<option value="">....</option>

							{props.lists.map((option, index) => (
								<option key={index} value={option}>
									{option}
								</option>
							))}
						</select>
						<div className="invalid-feedback">{props.eror[props.keyName]}</div>
					</div>
				)}

				{props.typeForm === "boolean" && (
					<div className={`col-8 col-sm-${props.col}`}>
						<input type="checkbox" value={true} checked={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)} className={classnames.form} />
						<div className="invalid-feedback">{props.eror[props.keyName]}</div>
					</div>
				)}

				{props.typeForm === "date" && (
					<div className={`col-8 col-sm-${props.col}`}>
						<input type="date" value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)} className={classnames.form} />
						<div className="invalid-feedback">{props.eror[props.keyName]}</div>
					</div>
				)}

				{props.typeForm === "text" && (
					<div className={`col-8 col-sm-${props.col}`}>
						<input type="text" value={props.keyValue} onChange={(event) => handleInputChange(props.keyName, event)} className={classnames.form} />
						<div className="invalid-feedback">{props.eror[props.keyName]}</div>
					</div>
				)}

				{props.typeForm === "number" && (
					<div className={`col-8 col-sm-${props.col}`}>
						<input
							type="text"
							value={props.keyValue}
							onChange={(event) => handleInputChange(props.keyName, event)}
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
									event.preventDefault()
								}
							}}
							className={classnames.form}
							maxLength={props.setmaxLength}
						/>
						<div className="invalid-feedback">{props.eror[props.keyName]}</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default FormInput
