import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { update, index, remove } from "../actions/crud";

//import ModalSuccess from "../components/Modals/ModalSuccess";
import PaginationList from "../components/Paginations/PaginationList";
import TabTop from "../components/TabBarComponents/TabTop";
import TabTitle from '../components/TabBarComponents/TabTitle';
import lib from '../libs';

import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData";
//import ModalApprove from "../components/Modals/ModalApprove";



const User = () => {
  const initialState = {
    title: 'User',
    api: 'user',
    modal: false,
    status: '',
    success: '',
    reload: true,
    keySearch: ['email','username'],
    form: {
      _id: '',
      status: '',
      email : '',
    }
  };



  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState(initialState);
  const datas = useSelector((state) => state.crud)
  const dispatch = useDispatch();



  const set_state_one = (key, val) => {
    setObj(prevData => ({
      ...prevData,
      [key]: val,
    }));
  };

  const set_state_two = (key, val) => {
    setObj(prevData => ({
      ...prevData,
      form: {
        ...prevData.form,
        [key]: val,
      },
    }));
  };







  const reset_ = () => {
    for (const key in initialState.form) {
      delete datas.errors[key];
      setObj((prevState) => ({
        ...prevState, form: {
          ...prevState.form, [key]: ''
        },
      }))
    }
  }

  useEffect(() => {
    dispatch(index(obj.api))
      .then(data => {
        if (data.success) {
          setLoading(true)
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);




  //removeNestedKey
  const add_ = () => {
    post_();
  }


  const successfull = (msg) => {
    set_state_one('status', '')
    set_state_one('success', msg)
  }


  const edit_ = (_id) => {
    reset_()
    datas.data.parents.forEach(row => {
      if (row._id === _id) {
        for (const key in initialState.form) {
          if (key === 'status') {
            if (row.status === 0) {
              set_state_two(key, 1)
            } else {
              set_state_two(key, 0)
            }
          } else {
            set_state_two(key, row[key])
          }
        }
      }
      set_state_one('status', 'approve')
    })
    
    
  }

  const post_ = () => {
    if (obj.status === 'approve') {
      dispatch(update(obj))
        .then(data => {
          if (data.success) {
            successfull("Updated")
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else if (obj.status === 'remove') {
      dispatch(remove(obj))
        .then(data => {
          if (data.success) {
            successfull("Removed")
          }
        })
        .catch(e => {
          console.log(e);
        });
    }

  };


  return (
    <div>
      <TabTitle title={obj.title} />
      <div className="tab-content border border-top-0 p-2 bg-white">
        <div className="tab-pane fade show active">
          {datas.data.parents == undefined && datas.data.parents == null ? (
            <SpinnerFetchingData />
          ) : (
            <>
              <div className="container-fluid border-0 p-2">
                <div className="row justify-content-between">
                  <div className="col-12 col-sm-12">
                    <TabTop parent_add={add_} keySearch={obj.keySearch} hideadd={true}/>
                    <div className="table-responsive p-0 mb-2">
                      <table className={`${lib.className.table.style}`}>
                        <thead>
                          <tr>
                            <th width="6%" className={`${lib.className.table.th} text-center`}>No.</th>
                            <th className={`${lib.className.table.th}`}>User ID</th>
                            <th className={`${lib.className.table.th}`}>Email</th>
                            <th className={`${lib.className.table.th} text-center`}>Date</th>
                          </tr>
                        </thead>
                        <tbody>

                          {datas.data.parents.slice(datas.start, datas.end).map((row, no) => (
                            <tr className="text-muted" key={no}>
                              {/* 
                              <td className="text-center p-0 border-left-0">
                                {row.status > 0 ? (
                                  <button className="btn btn-sm btn-outline-danger rounded-0 p-0 pl-1 pr-1"
                                    onClick={() => edit_(row._id)}>
                                    Cancel
                                  </button>
                                ) : (
                                  <button className="btn btn-sm btn-outline-success  rounded-0 p-0 pl-1 pr-1"
                                    onClick={() => edit_(row._id)}>
                                    Approval
                                  </button>
                                )}
                              </td>
                              */}
                              <td className={`${lib.className.table.td} text-center`}>{no + (datas.start + 1)} .</td>
                              <td className={`${lib.className.table.td}`}>&nbsp;{row.username} </td>
                              <td className={`${lib.className.table.td}`}>&nbsp;{row.email} </td>
                              <td className={`${lib.className.table.td} text-center`}> {lib.formatter.formatDate(row.createdAt)}</td>
                             
                              {/* 
                              {row.status > 0 ? (
                                <td className={`${lib.className.table.td} text-success text-center`}><i className="fa fa-check-circle"></i> Approved</td>
                              ) : (
                                <td className={`${lib.className.table.td} text-danger text-center`}><i className="fa fa-exclamation-circle"></i> Pending</td>
                              )}
                              */}
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <PaginationList
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default User;

