export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_DATA = "CREATE_DATA_SUCCESS";
export const INDEX_DATA = "SHOW_DATA";
export const UPDATE_DATA = "UPDATE_DATA_SUCCESS";
export const REMOVE_DATA = "REMOVE_DATA_SUCCESS";
export const SEARCH_DATA = "SEARCH_DATA";

export const SET_ITEM_PER_PAGE = "SET_ITEM_PER_PAGE";
export const SET_PAGE = "SET_PAGE";
export const SET_IMAGE = "SET_IMAGE_SUCCESS";
export const ERROR_INPUT = "ERROR_INPUT";

export const INCREMENT_PARENT_COUNT = "INCREMENT_PARENT_COUNT";
export const DECREMENT_PARENT_COUNT = "DECREMENT_PARENT_COUNT";
export const FILTER_SINGLE_PARENT = "FILTER_SINGLE_PARENT";
export const FILTERING = "FILTERING";
export const RELOADING = "RELOADING";
export const SET_SSO_GOOGLE = "SET_SSO_GOOGLE";
export const UPLOAD_FAKTUR = "UPLOAD_FAKTUR";
























