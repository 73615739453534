import {
  CREATE_DATA,
  INDEX_DATA,
  UPDATE_DATA,
  REMOVE_DATA,
  ERROR_INPUT,
  UPLOAD_FAKTUR

} from "./types";


import crud from "../services/crud";



export const fileupload = (id, file) => async (dispatch) => {
  const res = await crud.fileupload(id, file);
  if (res.data.success) {
    dispatch({
      type: UPLOAD_FAKTUR,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: ERROR_INPUT,
      errors: res.data.errors,
    });
  }
  return Promise.resolve(res.data);
};

export const addImage = (id, file) => async (dispatch) => {
  const res = await crud.upload(id, file);
  if (res.data.success) {
    dispatch({
      type: CREATE_DATA,
      payload: res.data.data,
    });
  } else {
    dispatch({
      type: ERROR_INPUT,
      errors: res.data.errors,
    });
  }
  return Promise.resolve(res.data);
};

export const download = (obj) => async () => {

  try {
    const res = await crud.show(obj);
    return Promise.resolve(res);
  } catch (err) {
    console.log(err);
  }
};

export const show = (obj) => async (dispatch) => {

  try {
    const res = await crud.show(obj);
    if (res.data.success) {
      dispatch({
        type: INDEX_DATA,
        payload: res.data.data,
      });
    }
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
  }
};

export const index = (api) => async (dispatch) => {
  console.log("index")
  try {
    const res = await crud.index(api);
    if (res.data.success) {
      dispatch({
        type: INDEX_DATA,
        payload: res.data.data,
      });
    }
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
  }
};


export const remove = (obj) => async (dispatch) => {
  try {
    const res = await crud.remove(obj);

    if (res.data.success) {
      dispatch({
        type: REMOVE_DATA,
        payload: res.data.data,
      });
    }
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const update = (obj) => async (dispatch) => {
  try {
    const res = await crud.update(obj);

    if (res.data.success) {
      dispatch({
        type: UPDATE_DATA,
        payload: res.data.data,
      });
    }
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const create = (obj) => async (dispatch) => {
  try {
    const res = await crud.create(obj);
    if (res.data.success) {
      dispatch({
        type: CREATE_DATA,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: ERROR_INPUT,
        errors: res.data.errors,
      });
    }
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




