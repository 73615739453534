const jsonStatic={};

jsonStatic.types = ['SUV', 'MPV', 'Crossover', 'Sedan', 'Sport Sedan', 'Convertible', 'Coupe', 'Station Wagon', 'Off road', 'Pickup Truck', 'Double Cabin', 'Elektrik', 'Hybrid', 'LCGC']
jsonStatic.colors = ['Merah', 'Hitam', 'Biru', 'Hijau', 'Abu Abu', 'Cokelat', 'Oranye', 'Putih']

jsonStatic.years = [];

for (let i = 2023; i >= 1970; i--) {
    jsonStatic.years.push(i);
}

export default jsonStatic;
