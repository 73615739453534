import React from "react";


const SpinnerFetchingData = () => {
    return (
        <div>
            <button className="btn btn-sm border-0" type="button" disabled>
                <div>
                    <span className="spinner-border spinner-border-sm"></span>
                    &nbsp; <b className="text-info">wait...</b>
                </div>
            </button>
        </div>
    );
};
export default SpinnerFetchingData;
