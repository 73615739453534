import React from "react";

import $ from 'jquery'; 

const ModalSuccess = (props) => {
    if (props.success !== '') {
        $("#modal-success").modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        });

        setTimeout(() => {
            $('#modal-success').modal('hide');
            props.set_state_one('success', '')
        }, 1000)
    }
    return (
        <div>
            <div className="modal fade" id="modal-success">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content rounded-0 bg-light">
                        <div className="modal-body text-center">
                            <div className="success-checkmark">
                                <div className="check-icon">
                                    <span className="icon-line line-tip"></span>
                                    <span className="icon-line line-long"></span>
                                    <div className="icon-circle"></div>
                                    <div className="icon-fix"></div>
                                </div>
                            </div>
                            <h5 className="text-muted">{props.success} &nbsp; Successfull .</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSuccess;
