import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
//import { index } from "../actions/crud";
import SpinnerFetchingData from "../components/Spinners/SpinnerFetchingData";
import lib from "../libs";
import ImagesSingle from "../components/Images/ImagesSingle";
import OtherProduct from "../components/GridsList/OtherProduct";
import ModalUploadFaktur from "../components/Modals/ModalUploadFaktur";
import ModalLogin from "../components/Modals/ModalLogin";

const Detail = () => {

  const initialState = {
    title: 'Mobil',
    api: 'mobil',
    status: '',
    success: '',
    reload: true,
    form: {
      id: '',
      merk: '',
      Type: '',
      Tahun: '',
      PlatNo: '',
      Warna: '',
      Km: '',
      Harga: '',
    }
  };


  const { id } = useParams();

  const datas = useSelector((state) => state.crud)
  const [obj, setObj] = useState(initialState);

  const [loading, setLoading] = useState(false);
  const [singleData, setSingleData] = useState();


  const set_state_one = (key, val) => {
    setObj(prevData => ({
      ...prevData,
      [key]: val,
    }));
  };


  const sendfaktur = () => {
    console.log(JSON.stringify(datas.ssoEmail.profileObj))
  }
  const uploadfaktur = () => {
    set_state_one('status', 'faktur')
  }

  useEffect(() => {

    if (datas.data.parents) {
      const filteredItems = datas.data.parents.find(item => item.id === id);
      setLoading(true)
      setSingleData(filteredItems)
    }

  }, [id]);


  return (
    <div>

      {!loading ? (
        <SpinnerFetchingData />
      ) : (
        datas.data.parents && (
          <>
            <div className="container mb-2 mt-4">
              <div className="row p-2 justify-content-center text-center mb-4 border-bottom">
                <div className="col-12 col-sm-6">
                  <ImagesSingle id={singleData.id} checkThumbnail={singleData.uploads} />
                </div>
                <div className="col-12 col-sm-5 shadow-sm bg-light">
                  <div className="p-1">
                    <div className="d-flex flex-row border-bottom mb-4">
                      <div className="p-1 text-info">
                        <h1>{singleData.merk.name}, {singleData.Type}</h1>
                      </div>
                    </div>

                    <div className="d-flex flex-row mb-2">
                      <div className="p-1 text-muted"><h5>Tahun :</h5> </div>
                      <div className="p-1"><h5>{singleData.Tahun}</h5></div>
                    </div>

                    <div className="d-flex flex-row mb-2">
                      <div className="p-1 text-muted"><h5>No.Plat :</h5> </div>
                      <div className="p-1"><h5>{singleData.PlatNo}</h5></div>
                    </div>

                    <div className="d-flex flex-row mb-2">
                      <div className="p-1 text-muted"><h5>Warna :</h5> </div>
                      <div className="p-1"><h5>{singleData.Warna}</h5></div>
                    </div>

                    <div className="d-flex flex-row mb-2">
                      <div className="p-1 text-muted"><h5>Km :</h5> </div>
                      <div className="p-1"><h5>{singleData.Km}</h5></div>
                    </div>

                    <div className="d-flex flex-row mb-2">
                      <div className="p-1 text-muted"><h5>Harga :</h5> </div>
                      <div className="p-1 text-danger"><h5>{lib.formatter.formatCurrencyIDR(singleData.Harga)}</h5></div>
                    </div>

                    <div className="d-flex">
                      {datas.ssoEmail.profileObj ? (
                        <div className="flex-grow-1 text-left border-top p-2">
                          <>
                            <div className="media">
                              <img src={datas.ssoEmail.profileObj.imageUrl} className="align-self-center mr-3" alt="my profile" />
                              <div className="media-body">
                                <>
                                  <h6 className="mt-0">{datas.ssoEmail.profileObj.email}</h6>
                                  <p>{`${datas.ssoEmail.profileObj.name}`}</p>
                                  <button className="btn btn-sm btn-outline-info rounded-0"
                                    onClick={uploadfaktur}>
                                    <i className="fa fa-upload"></i> Upload Faktur
                                  </button>
                                </>
                              </div>
                            </div>
                          </>
                        </div>
                      ) : (
                        <div className="border border-warning text-center p-2">
                          <h6 className="text-muted">
                            <i className="fa fa-exclamation-triangle text-warning"></i> Silahkan Login Untuk Mengupload Bukti Faktur .
                          </h6>
                        {/* 
                        <button className="btn btn-sm btn-outline-info rounded-0"
                          onClick={()=>set_state_one('login', true)}>
                          <i className="fa fa-upload"></i> Upload Faktur
                        </button>
                        */}                          
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <ModalUploadFaktur
                sendfaktur={sendfaktur}
                status={obj.status}
                idmob={singleData.id}
                set_state_one={set_state_one}
              />


            </div>

            <div className="container mb-4">
              <OtherProduct datagrid={datas.data.parents} />
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Detail;
