import React from "react";


const TabTitle = (props) => {

  return (
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <strong className="nav-link active rounded-0 text-muted" id="home-tab" data-toggle="tab" data-target="#home"
            type="button" role="tab" aria-controls="home" aria-selected="true"> { props.title }</strong>
        </li>
      </ul>
    </div>
  );
};

export default TabTitle;
