
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);



root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

serviceWorker.unregister();




